// Our main CSS
import { forEach } from '@splidejs/splide/src/js/utils'
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

if (document.querySelector('.sidebar')) {
  import('./sidebar').then((module) => {
    module.default()
  })
}

if (document.querySelector('nav')) {
  import('./nav').then((module) => {
    module.default()
  })
}

if (document.querySelector('.splide')) {
  import('./splide').then((module) => {
    module.default()
  })
}

if (document.querySelector('.faq-btn, .filter-btn')) {
  import('./accordions').then((module) => {
    module.default()
  })
}

if (document.querySelector('.range-slider')) {
  import('./range-slider').then((module) => {
    module.default()
  })
}

const lightbox = document.querySelector('.lightbox')
if (lightbox) {
  import('./lightbox').then((module) => {
    module.default()
  })
}